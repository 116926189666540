.open {
  position: fixed;
	z-index: 100;
  top: 30px;
  left: 40px;
  width: 55px;
  height: 55px;
  display: block;
  cursor: pointer;
  transition: opacity 0.2s linear;
  opacity: 0.95;
  background-color: rgb(0,4,40);
  border-radius: 10px;

  &:hover {
    opacity: 1;
  }

  .button-container {
    height: 55px;
    width: 55px;
    z-index: 200;
    position: absolute;
    transition: background-color 0.2s linear;
    border-radius: 10px;
    animation: burger-menu;
    animation-duration: 3s;
    animation-fill-mode: forwards;
  }

  @keyframes burger-menu {
    0% {
      opacity: 0;
      transform: translateX(-200px);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }

  .button-container:hover {
    background-color: rgb(0,4,40);
  }

  .cls {
    display: block;
    float: left;
    clear: both;
    height: 4px;
    width: 40px;
    border-radius: 40px;
    background-color: #fff;
    position: absolute;
    right: 7px;
    overflow: hidden;
    transition: all 0.4s ease;

    &:nth-child(1) {
      margin-top: 10px;
      z-index: 9;
    }

    &:nth-child(2) {
      margin-top: 25px;
    }

    &:nth-child(3) {
      margin-top: 40px;
    }
  }
}

.menu-options {
  height: 0;
  width: 0;
  position: absolute;
  top: -110px;
  left: -190px;
  transition: all 1s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.sub-menu {
  transition: all 0.8s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  height: 0;
  width: 0;
  left: 0;
  top: 0;
  position: absolute;
  cursor: default;

  li {
    display: block;
    height: auto;
    transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
		color: #fff;
		font-family: "Lato", Arial, Helvetica, sans-serif;
		font-size: 16px;
		line-height: 40px;
		font-weight: 900;

    div {
      cursor: pointer;
    }

    &:nth-child(1) {
			transition-delay: 0.05s;
      -webkit-transition-delay: 0.05s;
    }

    &:nth-child(2) {
      transition-delay: 0.1s;
			-webkit-transition-delay: 0.1s;
    }

    &:nth-child(3) {
      transition-delay: 0.15s;
			-webkit-transition-delay: 0.15s;
    }

    &:nth-child(4) {
      transition-delay: 0.2s;
			-webkit-transition-delay: 0.2s;
    }

    &:nth-child(5) {
      transition-delay: 0.25s;
			-webkit-transition-delay: 0.25s;
    }
  }
}

.open.openned {
  background: transparent;
}

.openned {
  .sub-menu {
    opacity: 1;
    height: 400px;
    width: 400px;
    background-color: rgba(38, 84, 133, 0.8);
    border-radius: 50%;
    z-index: 18;
    overflow: hidden;
    padding-top: 180px;
    padding-left: 200px;
  }

  span:nth-child(2) {
    height: 0;
    width: 0;
  }

  span:nth-child(1),
  span:nth-child(3) {
    z-index: 100;
    transform: rotate(45deg);
  }

  span:nth-child(1) {
    transform: rotate(45deg) translateY(12px) translateX(12px);
  }

  .menu-options {
    height: 400px;
    width: 400px;
    right: -160px;
    border-radius: 50%;
    background-color: rgba(38, 84, 133, 0.8);
  }

  span:nth-child(3) {
    transform: rotate(-45deg) translateY(-10px) translateX(10px);
  }
}

.hover-underline-animation {
  display: inline-block;
  position: relative;
  color: #ffffff;
}

.hover-underline-animation::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 3px;
  bottom: -5px;
  left: 0;
  background-color: #ffffff;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.hover-underline-animation:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
.notice {
    background: transparent;
    border: 3px solid #FFFFFF;
    padding: 0 1rem 0.5rem 1rem;
    margin: 3.5rem 0 2.5rem 0;
    position: absolute;
    top: 5rem;
    animation: announcement;
    animation-duration: 2.8s;
    animation-fill-mode: forwards;
}

@keyframes announcement {
    0% {
        opacity: 0;
        transform: translateX(200px);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

.notice::before {
    content: "ATTENTION";
    background: #FFFFFF;
    color: #000428;
    width: 7rem;
    border-right: 3px solid #000428;
    border-bottom: 3px solid #000428;
    display: block;
    text-align: center;
    position: relative;
    left: 0;
    top: -1rem;
    padding: 2px 10px;
    font-weight: bold;
}


@media (max-width: 991px) {
    .notice {
        top: 2rem;
    }
}

@media (max-width: 575.98px) {
    .notice {
        top: 2.8rem;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        font-size: 14px;
        padding: 0 0.5rem 0.2rem 0.5rem;

        .fw-bold {
            font-size: 16px !important;
        }
    }

    .notice::before {
        font-size: 12px;
    }
}

@media (max-width: 375px) {
    .notice {
        width: 90%;
        font-size: 12px;
        padding: 0 0.3rem 0.1rem 0.3rem;

        fw-bold {
            font-size: 14px !important;
        }
    }
}

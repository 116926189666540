.start {
  background-color: green;
  padding: 5px;
  padding-left: 15px;

  h2 {
    color: white;
    font-family: sans-serif;
  }
}

body {
  overscroll-behavior: none;
  font-family: Pumo,Helvetica,sans-serif;
}

.btn.contact-lg-btn {
  display: block;
  position: absolute;
  top: 60%;
  left: 3rem;
  font-size: 13px;
  font-weight: 700;
  color: rgba(255, 255, 255, 1);
  letter-spacing: 1px;
  line-height: 1.4;
  height: 50px;
  border: 2px solid rgba(255, 255, 255, 1);
  border-radius: 40px;
  background: transparent;
  transition: all 0.3s ease 0s;
  max-width: 250px;
}

.btn.contact-lg-btn:hover {
  color: rgba(0, 4, 40, 1);
  background: rgba(255, 255, 255, 1);
  border: 2px solid rgba(255, 255, 255, 1);
}

.contact-button {
  animation: button;
  animation-duration: 3s;
  animation-fill-mode: forwards;
}

@keyframes button {
  0% {
    opacity: 0;
    transform: translateX(200px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.links-container {
  position: absolute;
  bottom: 3.5rem;
  left: 3rem;
  animation: links;
  animation-duration: 3s;
  animation-fill-mode: forwards;

  a {
    color: #FFFFFF;
    text-decoration: none;
  }
}

@keyframes links {
  0% {
    opacity: 0;
    transform: translateY(200px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.links-lg-container {
  display: flex;
  flex-direction: column;
}

@media (max-width: 991.98px) {
  .btn.contact-lg-btn {
    display: none;
  }

  .links-lg-container {
    display: none;
  }
}

.self-image {
  animation: image;
  animation-duration: 5s;
  animation-fill-mode: forwards;
}

@keyframes image {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.fixed-container {
	position: fixed;
	top: 0;
	left: 0;
	height: 100vh;
	color: #ffffff;
	width: 50%;

	img {
		display: none;
	}

	h1 {
		font-weight: 900;
		font-family: Poppins, sans-serif;
		line-height: 1.3;
		animation: fixed-heading;
		animation-duration: 1.5s;
		animation-fill-mode: forwards;
	}

	@keyframes fixed-heading {
		0% {
			opacity: 0;
			transform: translateX(200px);
			
		}
		100% {
			opacity: 1;
			transform: translateX(0);
		}
	  }

	.introduction-text {
		margin-bottom: 5rem;
		animation: intro-text;
		animation-duration: 2.5s;
		animation-fill-mode: forwards;
	}

	@keyframes intro-text {
		0% {
			opacity: 0;
			transform: translateX(200px);
		}
		100% {
			opacity: 1;
			transform: translateX(0);
		}
	}

	.btn {
		font-size: 13px;
		font-weight: 700;
		color: rgba(255, 255, 255, 1);
		letter-spacing: 1px;
		line-height: 1.4;
		height: 50px;
		border: 2px solid rgba(255, 255, 255, 1);
		border-radius: 40px;
		background: transparent;
		transition: all 0.3s ease 0s;
		max-width: 250px;
		width: 50%;
	}

	.btn:hover {
		color: rgba(0, 4, 40, 1);
		background: rgba(255, 255, 255, 1);
		border: 2px solid rgba(255, 255, 255, 1);
	}

	.links-container {
		display: none;
	}
}

@media (max-width: 991.98px) {
	.fixed-container {
		position: sticky;
		width: 100%;
		text-align: center;
		min-height: 100vh;
		align-items: center;

		.introduction-text {
			margin-bottom: 0;
		}

		.btn {
			margin-left: auto;
			margin-right: auto
		}

		img {
			display: block;
			height: 200px;
			width: 200px;
		}

		.links-container {
			display: flex;
			flex-direction: column;
			left: 0;
			right: 0;
			justify-content: center;
			align-items: center;
		}
	}
}

@media (max-width: 575.98px) {
	.fixed-container {
		h1 {
			font-size: 20px;
		}

		img {
			height: 150px;
			width: 150px;
		}

		.btn {
			width: 70%;
		}
	}
}

@media (max-width: 399.98px) {
	.fixed-container {
		.introduction-text {
			font-size: 14px;
		}
	}
}
#projects-content {
	--swiper-theme-color: #000428;
	background-color: #FFFFFF;

	.slider {
		text-align: center;
		overflow: hidden;
		max-width: 650px;
	}
	.flex-auto {
		flex: auto;
	}
	  
	.slides {
		display: flex;
		border-radius: 10px;
		overflow-x: auto;
		scroll-snap-type: x mandatory;
		scroll-behavior: smooth;
		-webkit-overflow-scrolling: touch;
	}

	.slides::-webkit-scrollbar {
		width: 10px;
		height: 10px;
	}

	.slides::-webkit-scrollbar-thumb {
		background: black;
		border-radius: 10px;
	}

	.slides::-webkit-scrollbar-track {
		background: transparent;
	}

	.slide-item {
		scroll-snap-align: start;
		flex-shrink: 0;
		width: 100%;
		max-height: 650px;
		margin-right: 50px;
		border-radius: 10px;
		background: #eee;
		transform-origin: center center;
		transform: scale(1);
		transition: transform 0.5s;
		position: relative;
		
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 100px;
	}

	.swiper-button-prev {
		left: 5px;
	}
	
	.swiper-button-next {
		right: 5px;
	}

	.slider > a {
		display: inline-flex;
		width: 1.5rem;
		height: 1.5rem;
		background: white;
		text-decoration: none;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		margin: 0 0 0.5rem 0;
		position: relative;
	}

	.card {
		height: 650px;
		font-size: 20px;
		background-color: #EEEEEE;
		border: none;
		width: 100%;
		cursor: default;

		.card-body {
			font-size: 14px;
			color: rgba(0, 4, 40, 0.7);
			overflow-y: scroll;
			margin-bottom: 3.0rem;
		}

		.card-body::-webkit-scrollbar {
			-webkit-appearance: none;
			width: 5px;
			height: 10px;
		}
		
		.card-body::-webkit-scrollbar-thumb {
			border-radius: 5px;
			background: rgba(0, 0, 0, 0.5);
		}

		.card-title {
			display: inline;
		}

		.card-text {
			.hover-underline-animation::after {
				height: 1px;
				bottom: 1px;
			}
		}

		ul {
			text-align: left;
		}

		img {
			max-height: 180px;
			object-fit: cover;
			padding: 10px;
			border-radius: 20px;
		}

		.tech-stacks {
			margin-bottom: 10px;
		}

		.tech-item {
			display: inline-block;
			font-weight: 500;
			font-size: 12px;
			color: rgba(0, 4, 40, 0.7) !important;
			letter-spacing: 1px;
			line-height: 1.4;
			border: 1px solid rgba(0, 4, 40, 0.7) !important;
			border-radius: 40px;
			background: transparent;
			transition: all 0.3s ease 0s;
			max-width: 350px;
			margin: 0 7px 5px 0;
		}

		.tech-item:hover {
			transform: scale(1.1);
		}

		.project-links {
			font-size: 14px;

			a {
				margin-right: 14px;
				color: rgba(0, 4, 40, 0.7) !important;
				text-decoration: none;
				cursor: pointer;
				font-weight: 600;

				svg {
					transition: transform 0.25s ease-in-out;
				}
			}

			a:hover {
				svg {
					transform: scale(1.3);
				}
			}

			a.hover-underline-animation::after {
				height: 1px;
			}
		}
	}

	@media (max-width: 575.98px) {
		.slider {
			margin-top: 10px;
		}

		.card {
			height: 600px;

			img {
				max-height: 80px;
			}
	
			.card-body {
				font-size: 12px;
				color: rgba(0, 4, 40, 0.7);
			}
	
			.card-title {
				font-size: 15px;
				margin-bottom: 2px;
			}
	
			ul {
				margin-bottom: 5px;
			}
	
			.tech-stacks {
				margin-bottom: 5px;
			}
	
			.tech-item {
				font-size: 10px;
			}
		}
	}
}

.text-left {
	text-align: left;
}

.inline-links {
	font-weight: 600;
	color: rgba(0, 4, 40, 1);
}
#introduction-content {
	background: rgb(0,4,40);
	background: linear-gradient(90deg, rgba(0,4,40,1) 10%, rgb(75, 75, 75) 100%);

	img {
		height: 300px;
		width: 300px;
	}
}

@media (max-width: 991.98px) {
	#introduction-content {
		display: none !important;
	}
}